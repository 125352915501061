.services-container {
  margin: 0 auto;
  padding-top: 5em;
  padding-left: 2em;
}

.service-card {
  display: flex;
  align-items: stretch;
  gap: 3rem;
  padding: 2em;
  border-bottom: 1px solid #d7d7d7;
  border-top: 1px solid #d7d7d7;
}

.service-image-container {
  width: 320px;
  flex-shrink: 0;
}

.service-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 14px;
  background-color: #f3f4f6;
}

.service-content {
  flex-grow: 1;
}

.service-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  margin-bottom: 1.5rem;
  font-family: "Helvetica-Regular";
  letter-spacing: -0.5px;
}

.service-title {
  font-size: 1.875rem;
  line-height: 1.2;
}

.service-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: flex-end;
}

.service-tag {
  padding: 0.5rem 1.25rem;
  background-color: transparent;
  color: #374151;
  border-radius: 9999px;
  border: 2px solid #b6b6b6;
  font-size: 0.875rem;
  white-space: nowrap;
}

.service-description {
  color: #4b5563;
  max-width: 42rem;
  line-height: 1.5;
}

.service-line-container {
  padding: 0 1rem;
}

.service-line {
  height: 1px;
  background-color: #eee;
  transform-origin: left center;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .service-card {
    flex-direction: column;
    gap: 2rem;
    padding-left: 0;
    margin-left: 0;
    border: none;
  }

  .service-image-container {
    width: 100%;
    height: 240px;
  }

  .service-header {
    flex-direction: column;
    gap: 1rem;
  }

  .service-tags {
    justify-content: flex-start;
  }
  .services-container {
    margin-left: 0;
  }
}
