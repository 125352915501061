/* Main navbar container */
.navbarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em;
  position: relative;
}

/* Time display styling */
.time {
  text-align: center;
  font-family: "Helvetica-Regular";
  font-size: 16px;
  background: linear-gradient(90deg, #0a0a0a 0%, #707070 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Menu button styling */
.menu {
  text-align: right;
  font-size: 20px;
  font-family: "Helvetica-Medium";
  cursor: pointer;
  background: linear-gradient(90deg, #0a0a0a 0%, #707070 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Fullscreen menu container */
.fullscreenMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f9f6ee; /* Adjust the color to match your design */
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Close button */
.close {
  text-align: right;
  font-size: 20px;
  font-family: "Helvetica-Medium";
  cursor: pointer;
  background: linear-gradient(90deg, #0a0a0a 0%, #707070 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  top: 1.2em;
  right: 2.5em;
}

/* Menu items */
.menuItems p {
  font-size: 48px;
  margin: 20px 0;
  cursor: pointer;
  font-family: "Helvetica-Regular";
  color: #333;
  padding-left: 1em;
}

.footer {
  position: absolute;
  bottom: 30px;
  font-family: "Helvetica-Medium";
  color: #707070 !important;
}
/* Prevent scrolling when the menu is open */
.no-scroll {
  overflow: hidden;
}
.logo {
  height: 1.5em;
  width: auto;
}
