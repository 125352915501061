.heroName {
  font-size: 8em;
  font-family: "Helvetica-Medium";
  letter-spacing: -3px;
  color: #0a0a0a;
  text-align: center;
  line-height: 0;
  width: 100%;
  max-width: 100%;
  white-space: normal;
  overflow-wrap: break-word;
}

.green {
  background: #24ff50;
}
.circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
.availableText {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  padding-top: 0.5em;
  font-family: "Helvetica-Regular";
  line-height: 0;
  background: linear-gradient(49deg, #0a0a0a 22%, #707070 60%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.leftText {
  color: #595959;
  font-size: 18px;
  font-family: "Helvetica-Regular";

  width: 20em;
}
.leftText span {
  color: #212121;
  font-size: 16px;
  font-family: "Helvetica-Medium";
  line-height: normal;
}
.heroSubTextContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 3em;
  padding-left: 2em;
  padding-right: 2em;
}
.heroContainer {
  height: 39vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.talkButton {
  width: 50%;
  max-width: 180px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 50.66px;
  border: 4px solid #e0e0e0;
  background: #0a0a0a;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  text-align: center;
  margin: 0 auto;
}

.btnText {
  font-size: 16px;
  font-family: "Helvetica-Medium";
  color: #fcf9f0;
  text-decoration: none;
}

/* responsive */
@media only screen and (max-width: 320px) {
  .heroName {
    font-size: 2em;
    line-height: 1;
  }
}
@media only screen and (max-width: 480px) {
  .heroName {
    font-size: 3em;
    line-height: 1;
  }
}

@media only screen and (max-width: 890px) {
  .heroName {
    font-size: 3.7em;
    line-height: 1;
  }
  .heroContainer {
    height: 37vh;
  }
  .scrollInstructionTextPc {
    display: none;
  }
  .leftText {
    text-align: center;
    font-size: 16px;
  }
  .leftText span {
    text-align: center;
    font-size: 16px;
  }
  .heroSubTextContainer {
    padding-top: 2em;
    padding-bottom: 2em;
    justify-content: center;
  }
  .talkButton {
    margin-bottom: 3em;
  }
}

@media only screen and (max-width: 1200px) {
  .heroName {
    font-size: 4em;
    line-height: 1;
  }
}
