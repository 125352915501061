* {
  -webkit-user-drag: none;
  user-select: none;
  max-width: 100%;
  box-sizing: border-box;
}

body {
  font-family: "Helvetica-Regular";
  /* Make Sure To Add Global Fonts */
  background-color: #fcf9f0;
  overflow-x: hidden;
}

@font-face {
  font-family: "Helvetica-Light";
  src: url("./assets/fonts/HelveticaNowDisplay-Light.woff") format("opentype");
}
@font-face {
  font-family: "Helvetica-Regular";
  src: url("./assets/fonts/HelveticaNowDisplay-Regular.woff") format("opentype");
}
body {
  /* Make Sure To Add Global Fonts */
  background-color: #fcf9f0;
  overflow-x: hidden;
}
* {
  max-width: 100%;
  box-sizing: border-box;
}

@font-face {
  font-family: "Helvetica-Light";
  src: url("./assets/fonts/HelveticaNowDisplay-Light.woff") format("opentype");
}
@font-face {
  font-family: "Helvetica-Regular";
  src: url("./assets/fonts/HelveticaNowDisplay-Regular.woff") format("opentype");
}
@font-face {
  font-family: "Helvetica-Medium";
  src: url("./assets/fonts/HelveticaNowDisplay-Medium.woff") format("opentype");
}
@font-face {
  font-family: "Helvetica-Bold";
  src: url("./assets/fonts/HelveticaNowDisplay-Bold.woff") format("opentype");
}

::-webkit-scrollbar {
  width: 10px;
  cursor: pointer !important;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  cursor: pointer !important;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d;
  cursor: pointer !important;
}
